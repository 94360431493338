/// <reference types="w3c-web-usb" />
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import ReceiptPrinterEncoder from '@point-of-sale/receipt-printer-encoder';
import { SectionTicket } from 'src/app/models/tickets';
import { KdsSettings } from 'src/app/models/user';

class PrinterError extends Error {
  status: string;
  device?: USBDevice;

  constructor({
    message,
    status,
    device,
  }: {
    message: string;
    status: string;
    device?: USBDevice;
  }) {
    super(message);
    this.status = status;
    this.device = device;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ThermalPrintService {
  constructor(private transloco: TranslocoService) {}

  CODEPAGE: codepageType | 'auto' = 'auto';
  endpointNumber = 1;
  errorHint = 'Try again, disconnecting and reconnecting the printer.';
  device!: USBDevice;
  encoder = new ReceiptPrinterEncoder();

  /**
   * Opens a prompt for the user to select a printer
   * @returns USB Device
   */
  requestPrinter = async (): Promise<USBDevice> => {
    if (!navigator.usb)
      throw new PrinterError({
        message:
          'WebUSB is not supported by your browser. Please use Chrome or Edge.',
        status: 'not_supported',
      });
    return await navigator.usb.requestDevice({ filters: [] });
  };

  /**
   * Connects with a paired printer and claims the interface
   * @returns USB Device
   */
  connectPrinter = async (): Promise<USBDevice> => {
    this.device = await this.getDevices();
    if (!this.device)
      throw new Error(
        'No printer is paired. Pair a printer first before connecting.',
      );
    await this.device.open().catch(() => {
      throw new PrinterError({
        device: this.device,
        message:
          'The session with the printer could not be started. ' +
          this.errorHint,
        status: 'connection_lost',
      });
    });
    await this.device.selectConfiguration(1);
    if (!this.device.configuration) {
      throw new PrinterError({
        device: this.device,
        message:
          'The configuration of the printer could not be loaded. ' +
          this.errorHint,
        status: 'connection_lost',
      });
    }
    // Claim the interface: error is thrown if another browser already claimed the interface
    await this.device
      .claimInterface(this.device.configuration.interfaces[0].interfaceNumber)
      .catch(() => {
        throw new PrinterError({
          device: this.device,
          message:
            'The interface of the printer could not be claimed. ' +
            this.errorHint,
          status: 'device_busy',
        });
      });
    this.endpointNumber = 1;
    return this.device;
  };

  /**
   * Disconnects from the printer
   */
  disconnectPrinter = (): void => {
    if (!this.device) return;
    this.device.close();
    this.device.forget();
  };

  /**
   * Gets currently paired printer
   * @returns USB Device
   */
  private getDevices = async (): Promise<USBDevice> =>
    navigator.usb?.getDevices().then(([printer]) => Promise.resolve(printer));

  /**
   * Returns the name of the printer
   * @param device USB Device to get the name from
   * @returns Printer name
   */
  getPrinterName = (device: USBDevice) => device.manufacturerName ?? '';

  /**
   * Creates a paper-cut
   */
  cutPaper = (): void => {
    if (!this.device) return;
    const content = this.encoder
      .initialize()
      .newline()
      .newline()
      .newline()
      .newline()
      .cut()
      .encode();
    this.startPrint(this.device, content);
  };

  /**
   * Feed printer
   */
  feedPaper = (): void => {
    if (!this.device) return;
    const content = this.encoder
      .initialize()
      .newline()
      .newline()
      .newline()
      .newline()
      .encode();
    this.startPrint(this.device, content);
  };

  private startPrint = async (device: USBDevice, content: any) =>
    device.transferOut(this.endpointNumber, content).catch((e: any) => {
      if (
        e.message.includes(
          'The specified endpoint is not part of a claimed and selected alternate interface',
        )
      ) {
        if (this.endpointNumber < 15) {
          this.endpointNumber = this.endpointNumber + 1;
          this.startPrint(device, content);
          return;
        }
        console.log('failed!');
        return;
      }
      console.error('Send Error:', e);
    });

  private showHeader = (settings: KdsSettings, data: SectionTicket): boolean =>
    settings.show_consumer_name ||
    (settings.show_order_table && data.service_table_number) ||
    (settings.show_consumer_room && (data.room || data.room_floor)) ||
    (settings.show_consumer_type && data.type_name) ||
    (settings.show_consumer_diets && data.diets?.length) ||
    (settings.show_consumer_profile && data.custom_data);

  /**
   * Prints an order ticket
   */
  // eslint-disable-next-line sonarjs/cognitive-complexity
  printTicket(data: SectionTicket, settings: KdsSettings) {
    const date = new Date(data.date).toLocaleString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    const result = this.encoder.codepage(this.CODEPAGE).initialize();

    // ticket header
    if (
      (settings.show_order_table && data.service_table_number) ||
      settings.show_consumer_name
    ) {
      let header = '';
      if (settings.show_order_table && data.service_table_number) {
        header = '#' + data.service_table_number;
      }
      if (settings.show_consumer_name && data.name) {
        header ? (header += ' ' + data.name) : (header = data.name);
      }
      result
        .align('center')
        .bold()
        .invert()
        .font('B')
        .line(' ' + header + ' ')
        .font('A')
        .bold(false)
        .invert()
        .newline()
        .align('center')
        .line(
          new Date(data.date).toLocaleTimeString(undefined, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          }),
        )
        .newline();
    }
    if (settings.show_consumer_room && (data.room || data.room_floor)) {
      let room = '';
      if (data.room) {
        room = data.room;
      }
      if (data.room_floor) {
        room ? (room += '/' + data.room_floor) : (room = data.room_floor);
      }
      result.align('center').line(room);
    }
    if (settings.show_consumer_type && data.type_name) {
      result.align('center').line(data.type_name);
    }
    if (settings.show_consumer_diets && data.diets?.length) {
      result.align('center').line(data.diets.join(', '));
    }
    if (settings.show_consumer_profile) {
      let profile = '';

      if (data.consumer_texture) {
        profile +=
          this.transloco.translate('tickets.texture.' + data.consumer_texture) +
          ', ';
      }
      if (data.consumer_consistency) {
        profile +=
          this.transloco.translate(
            'tickets.consistency.' + data.consumer_consistency,
          ) + ', ';
      }
      if (data.consumer_allergies?.length) {
        profile +=
          this.transloco.translate('tickets.dietary_profile.allergies') +
          ': ' +
          data.consumer_allergies.join(', ') +
          ', ';
      }
      if (data.consumer_intolerances?.length) {
        profile +=
          this.transloco.translate('tickets.dietary_profile.intolerances') +
          ': ' +
          data.consumer_intolerances.join(', ') +
          ', ';
      }
      if (data.diets?.length) {
        profile += data.diets.join(', ') + ', ';
      }
      if (data.portion_size && data.consumer_portion_size !== 1) {
        profile +=
          this.transloco.translate(
            'tickets.portion-size.' + data.consumer_portion_size * 100,
          ) + ', ';
      }
      if (data.custom_data) {
        Object.entries(data.custom_data).forEach(([k, v]) => {
          profile += k + ': ' + v + ', ';
        });
      }

      if (profile) profile = profile.slice(0, -2);
      result.align('center').line(profile);
    }

    // print horizontal line if a header is present
    if (this.showHeader(settings, data)) {
      result.align('center').align('left').line('_'.repeat(40));
    }

    // service and date
    const service = data.service ? data.service + ', ' : '';
    result
      .bold()
      .newline()
      .underline()
      .line(service + date)
      .bold(false)
      .underline(false)
      .newline();

    // order items
    for (let i = 0; i < data.count_orders; i++) {
      if (data.quantity[i] !== 1) result.text(data.quantity[i] + ' x ');

      result.text(data.item[i] + ' ' + data.variants[i]);
      if (data.description[i])
        result
          .italic(true)
          .text(' ' + data.description[i])
          .italic(false);

      const portionSize =
        data.portion_size[i] && data.portion_size[i] !== 1
          ? this.transloco.translate(
              'tickets.portion-size.' + data.portion_size[0] * 100,
            )
          : '';
      if (portionSize) result.text(' (' + portionSize + ')');

      const consistency = data.consistency[i]
        ? this.transloco.translate('tickets.consistency.' + data.consistency[i])
        : '';
      const texture = data.texture[i]
        ? this.transloco.translate('tickets.texture.' + data.texture[i])
        : '';
      const separ = consistency && texture ? ' + ' : '';
      if (consistency || texture)
        result.text(`(${consistency}${separ}${texture})`);
      result.newline();
    }

    // all items on a ticket will have the same order diets, hence we only print the first one
    if (data.order_diets?.length && data.order_diets[0]) {
      result.align('center').line('= ' + data.order_diets[0]);
    }

    // ticket footer
    const printDetail = result
      .newline()
      .newline()
      .newline()
      .newline()
      .cut()
      .encode();

    // print ticket
    this.startPrint(this.device, printDetail);
  }
}
